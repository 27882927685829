import { CheckIcon } from "@naf/teamscheme";
import { type ITheme, onDesktop } from "@naf/teamscheme";
import type { HTMLAttributes, ReactNode } from "react";
import styled, { css } from "styled-components";
import { normalizeComponentTree } from "../utils/normalizeComponentTree";

export enum PointState {
  None = "none",
  Completed = "completed",
  Inactive = "inactive",
  Potential = "potential",
}

export type PointSize = "regular" | "large";

interface SizeProps {
  theme: ITheme;
  size: PointSize;
}

function circleMarginRight({ size, theme }: SizeProps) {
  return size === "large" ? theme.spacing.space32 : theme.spacing.space16;
}

function circleSize({ theme, size }: SizeProps) {
  switch (size) {
    case "regular":
      return theme.spacing.space40;
    case "large":
      return theme.spacing.space48;
    default:
      throw new Error(`Unrecognized size ${size}`);
  }
}

function regularSize({ theme }: { theme: ITheme }): SizeProps {
  return { theme, size: "regular" };
}

function largeSize({ theme }: { theme: ITheme }): SizeProps {
  return { theme, size: "large" };
}

export interface PointSpecification {
  key?: string;
  title: JSX.Element | string;
  content?: JSX.Element | string;
  status: PointState;
}

export interface PointsProps extends HTMLAttributes<HTMLUListElement> {
  points: PointSpecification[];
  hideLine?: boolean;
}

const PointList = styled(function ({
  points,
  hideLine,
  renderCircle,
  ...props
}: PointsProps & {
  renderCircle: (point: PointSpecification, index: number) => ReactNode;
}) {
  const renderPoints = points.reduce(
    (acc, x) => {
      const last = acc[acc.length - 1];

      if (!last) return [{ current: x }];

      return [
        ...acc.slice(0, acc.length - 1),
        { ...last, next: x },
        { current: x },
      ];
    },
    [] as { current: PointSpecification; next?: PointSpecification }[],
  );

  return (
    <ul {...props}>
      {renderPoints.map(({ current, next }, i) => {
        const { key, title, content, status } = current;
        return (
          <Point key={key || normalizeComponentTree(title)} state={status}>
            {!hideLine ? (
              <PointMarker>
                {next ? <PointMarkerLine to={next.status} /> : null}
              </PointMarker>
            ) : null}
            <PointContent>
              <PointContentHeader>
                <PointMarkerCircle state={status}>
                  {renderCircle(current, i)}
                </PointMarkerCircle>
                <h3>{title}</h3>
              </PointContentHeader>
              {content ? <PointContentBody>{content}</PointContentBody> : null}
            </PointContent>
          </Point>
        );
      })}
    </ul>
  );
})`
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
`;

interface PointNumberProps extends HTMLAttributes<HTMLDivElement> {
  number: number;
  status: PointState;
}

export const PointNumber = styled(
  ({ number, status, ...props }: PointNumberProps) => (
    <div {...props}>{number}</div>
  ),
)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 100%;
  ${({ theme }) => css`
    ${theme.mobileFontStyle.headers.subHeader}
    ${onDesktop`${theme.fontStyle.headers.subHeader}`}
  `}
  ${({ theme, status }) =>
    status &&
    css`
      border-color: ${theme.pointMarkerCircle[status].borderColor};
      background: ${theme.pointMarkerCircle[status].background};
      color: ${theme.pointMarkerCircle[status].color};
    `}
`;

export const ProgressNumberList = (props: PointsProps) => (
  <PointList
    {...props}
    renderCircle={(p, i) =>
      p.status === PointState.Completed ? (
        <CheckIcon title="Utført" />
      ) : (
        <PointNumber number={i + 1} status={p.status} />
      )
    }
  />
);

interface PointProps extends HTMLAttributes<HTMLLIElement> {
  state: PointState;
}

interface PointMarkerLineProps extends HTMLAttributes<HTMLDivElement> {
  to: PointState;
}

const PointMarker = styled.div``;

export interface PointMarkerCircleProps extends HTMLAttributes<HTMLDivElement> {
  state: PointState;
}

export const PointMarkerCircle = styled(function ({
  state,
  ...props
}: PointMarkerCircleProps) {
  return <div {...props} />;
})`
  border-radius: 50%;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.25em;
  box-sizing: border-box;
  z-index: 1;
  position: relative;

  ${(props) => {
    const regular = regularSize(props);
    const large = largeSize(props);
    const { space24, space32, space4 } = props.theme.spacing;
    return css`
      height: ${circleSize(regular)};
      width: ${circleSize(regular)};
      flex: 0 0 ${circleSize(regular)};
      margin: 0 ${circleMarginRight(regular)} 0 0;

      ${CheckIcon} {
        width: ${space24};
      }

      ${onDesktop<HTMLAttributes<HTMLDivElement>>`
        height: ${circleSize(large)};
        width: ${circleSize(large)};
        flex: 0 0 ${circleSize(large)};
        margin: 0 ${circleMarginRight(large)} 0 0;

        ${CheckIcon} {
          width: ${space32};
          width: calc(${space24} + ${space4});
        }
      `}
    `;
  }}

  ${({ theme, state }) =>
    state &&
    css`
      border-color: ${theme.pointMarkerCircle[state].borderColor};
      background: ${theme.pointMarkerCircle[state].background};
      color: ${theme.pointMarkerCircle[state].color};
    `}
`;

const PointMarkerLine = styled(({ to, ...props }: PointMarkerLineProps) => (
  <div {...props} />
))`
  box-sizing: border-box;
  position: absolute;
  top: 12px;
  bottom: -12px;
  ${(props) => css`
    left: calc(0.5 * ${circleSize(regularSize(props))});

    ${onDesktop`
      left: calc(0.5 * ${circleSize(largeSize(props))});
    `}
  `}
  border-right-style: dashed;
  border-width: 2px;
  border-right-color: ${({ theme }) => theme.pointMarkerLine.color};
`;

const PointContent = styled.div``;
interface PointContentHeaderProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
}

const PointContentHeader = styled(function (props: PointContentHeaderProps) {
  const { children, ...rest } = props;
  return <div {...rest}>{children}</div>;
})`
  display: flex;
  align-items: flex-start;

  h3 {
    color: black;
    flex: 1 1 auto;
    margin: 0 0 0.5em 0;
    ${(props) => css`
      min-height: ${circleSize(regularSize(props))};

      ${onDesktop`
        min-height: ${circleSize(largeSize(props))};
      `}
    `}
    display: flex;
    align-items: center;
  }
`;

const PointContentBody = styled.div`
  ${(props) => {
    const regular = regularSize(props);
    const large = largeSize(props);
    return css`
      padding-left: calc(
        ${circleMarginRight(regular)} + ${circleSize(regular)}
      );

      ${onDesktop`
        padding-left: calc(${circleMarginRight(large)} + ${circleSize(large)});
      `}
    `;
  }}
`;

export const Point = styled(({ state, ...props }: PointProps) => (
  <li {...props} />
))`
  position: relative;
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    padding-bottom: ${({ theme }) => theme.spacing.space40};
  }

  &:not(:last-child) > ${PointContent} > ${PointContentBody} {
    margin-bottom: ${({ theme }) => theme.spacing.space16};

    ${({ theme }) => onDesktop`
      margin-bottom: ${theme.spacing.space32};
    `}
  }
`;
